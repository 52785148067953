<template>
  <div class="tab-content" id="PTtab-7" :key="'PTtab-7'">
    <ul class="tablist">
      <li class="tabmenu" @click="onChangeTab('partnerCashList')">{{ $t('front.mypage.depositWithdrawalList') }}</li>
      <li class="tabmenu current" @click="onChangeTab('partnerMoneyMoveList')">{{ $t('front.stributor.m5') }}/{{ $t('front.common.retriDetail') }}</li>
      <!--li class="tabmenu" data-tab="moneytab_2" @click="currentTab = 'moneytab_2'" :class="{'current': currentTab == 'moneytab_2'}">하부머니 지급/회수 내역</!--li>
      <li class="tabmenu" data-tab="moneytab_1" @click="currentTab = 'moneytab_1'" :class="{'current': currentTab == 'moneytab_1'}">하부머니 지급/회수</li-->
    </ul>
    <div id="moneytab_1" :key="'moneytab_1'" class="tab-content" v-if="currentTab == 'moneytab_1'">
      <div class="sch_PC">
        <div class="PTsch flex-c">
          <div class="datesearchPTWarp flex-c gap-2">
            <h4>날짜</h4>
            <date-filter :retail="true" :isOldYn="true" :id="'main-date-checkbox1'" />
          </div>
        </div>
      </div>
      <div class="sch_M">
        <div class="PTsch flex-c">
          <div class="datesearchM">
            <date-filter-mobile :retail="true" :isOldYn="true" :id="'main-date-checkbox1'" />
          </div>
        </div>
      </div>
      <div id="userMoney">
        <div class="controlWrap">
          <ul>
            <li class="m_w30p">
              <h4>등급</h4>
              <select v-model="selectPartnerCode">
                <option value="" disabled selected>{{$t('front.give.bottomPatner')}}</option>
                <template v-for="item in partnerLevelList" v-bind:key="item.code">
                  <option :value="item.code">{{item.codeName}}</option>
                </template>
              </select>
            </li>
            <li class="m_w30p">
              <h4>대상 아이디</h4>
              <select v-model="selectMem">
                <option value="" disabled selected>{{$t('front.give.resultIDs')}}</option>
                <template v-for="member in myMemIdsList" v-bind:key="member.memId">
                  <option :value="member">{{member.memId}}({{member.memNick}})</option>
                </template>
              </select>
            </li>
            <li class="m_w30p w100w">
              <h4>대상 아이디 보유금</h4>
              <input type="text" :placeholder="$t('front.give.targetMoney')" v-model="selectMem.cashAmt" readonly class="w100w"/>
            </li>
            <li class="w100w">
              <h4>지금/회수 금액</h4>
              <input type="text" :placeholder="$t('front.give.moneyInput')" :value="formattedCashAmt" @input="cashAmt = parseFormattedValue($event.target.value)" class="w100w"/>
            </li>
            <li>
              <div class="btnWrap2">
                <a class="bgb normal-btn" @click="memCash('-3')">{{$t('front.give.give')}}</a>
                <a class="bgr normal-btn" @click="memCash('3')">{{$t('front.give.back')}}</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="pagenamPT">
          <h3 class="pagename2">현재 접속중인 회원</h3>
        </div>
        <div>
          <div class="strTablescr">
            <div class="strTablePC">
                <table class="strTablePT">
                  <colgroup>
                    <col width="10%">
                    <col width="10%">
                    <col width="10%">
                    <col width="10%">
                    <col width="10%">
                  </colgroup>
                  <thead>
                  <tr>
                    <th>아이디</th>
                    <th>닉네임</th>
                    <th>보유금</th>
                    <th>로그인 시간</th>
                    <th>로그인 횟수</th>
                  </tr>
                  </thead>
                  <tbody>
                    <template v-if="loginMemList.length > 0">
                      <template v-for="(item) in loginMemList" :key="item.memId">
                        <tr>
                          <td>{{item.memId}}</td>
                          <td class="nick">{{item.memNick}}</td>
                          <td>{{thousand(item.cashAmt)}}</td>
                          <td>{{dateFormatAll(item.loginDt)}}</td>
                          <td>{{thousand(item.totalLoginCount)}}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="6">현재 접속 중인 회원이 없습니다.</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
            </div>
            <div class="strTableM">
                <div class="strTablePTM">
                  <template v-if="loginMemList.length > 0">
                      <template v-for="(item) in loginMemList" :key="item.memId">
                        <ul>
                          <li><em>아이디</em>{{item.memId}}</li>
                          <li><em>닉네임</em>{{item.memNick}}</li>
                          <li><em>보유금</em>{{thousand(item.cashAmt)}}</li>
                          <li><em>로그인 시간</em>{{dateFormatAll(item.loginDt)}}</li>
                          <li><em>로그인 횟수</em>{{thousand(item.totalLoginCount)}}</li>
                        </ul>
                      </template>
                    </template>
                    <template v-else>
                    <ul>
                        <li class="nodata">내역 없음</li>
                    </ul>
                  </template>
                </div>
            </div>
          </div>
          <pagination v-if="pageInfo"
                      :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      :className="'partnerPaging'"
                      @goToPage="loadData"
          />
        </div>
      </div>
    </div>
    <div id="moneytab_2" :key="'moneytab_2'" class="tab-content" v-if="currentTab == 'moneytab_2'">
      <div class="sch_PC">
        <div class="PTsch flex-c">
          <div class="datesearchPTWarp flex-c gap-2">
            <h4>{{ $t('front.give.date') }}</h4>
            <date-filter :retail="true" :isOldYn="true" :id="'main-date-checkbox1'" />
          </div>
        </div>
      </div>
      <div class="sch_M">
        <div class="PTsch flex-c">
          <div class="datesearchM">
            <date-filter-mobile :retail="true" :isOldYn="true" :id="'main-date-checkbox1'" />
          </div>
        </div>
      </div>
      <div class="sch_Normal">
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT">
              <div class="datesearchPT flex-c gap-2">
                <h4>{{ $t('front.give.yesorno') }}</h4>
                <select class="h36px">
                  <option>{{ $t('front.give.ok') }}</option>
                  <option>{{ $t('front.give.fail') }}</option>
                </select>
              </div>
              <div class="datesearchPT flex-c gap-2">
                <h4>{{ $t('front.search.content') }}</h4>
                <select class="h36px">
                  <option>{{ $t('front.give.give') }}</option>
                  <option>{{ $t('front.give.back') }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="datesearchPT flex-c gap-2 w30p">
              <h4>{{ $t('front.board.type') }}</h4>
              <select class="h36px">
                <option>{{ $t('front.common.memId') }}</option>
                <option>{{ $t('front.common.nickName') }}</option>
                <option>{{ $t('front.common.topUser') }}</option>
              </select>
            </div>
            <div class="searchPT">
              <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchForm.botMemId" class="h36px"/>
              <a @click="loadData(1)" class="sch_Icon">
                <img src="@/assets/img/search.png" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--
        <div class="datesearchPTWarp flex-c gap-2">
          <h4>날짜</h4>
          <div class="PTsch mb0">
            <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
            <span>~</span>
            <input type="date" :value="searchForm.endDate" @change="onChangeEndDate" class="mobile_margin"/>
          </div>
        </div>
        <div class="datesearchM datesearchPTWarp">
          <input type="date" :value="searchForm.startDate" @change="onChangeStartDate" class="mobiledate"/>
          <span>~</span>
          <input type="date" :value="searchForm.endDate" @change="onChangeEndDate" class="mobile_margin mobiledate"/>
        </div>
      -->
      <div class="strTableWrap">
        <div class="subMemWrap">
          <!-- <p class="p10"></p> -->
          <ul class="subPT_1">
            <!-- <li>
              <a>
                <span class="box" :class="userData.partnerLevel">{{partnerLevelName(userData.partnerLevel)}}</span>
                {{userData.memId}}
              </a>
            </li> -->
            <h3>{{ $t('front.common.lowerPartnerList') }}<a @click="idlist=!idlist">i</a><span v-if="idlist" @click="idlist=!idlist">{{ $t('front.common.clickID') }}</span></h3>
            <li v-for="(item1) in partnerList" :key="item1.memId" :class="{'morebar':item1.open}">
              <span class="arr" v-if="item1.cnt" :class="[item1.partnerLevel, {'moreOn':item1.open}]" @click="listOpen(item1.memId, 'sub', item1)"></span>
              <span class="arr normal" v-if="!item1.cnt"></span>
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">{{ $t('front.common.hq') }}</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId" :class="{'morebar':item2.open}">
                  <span class="arr" v-if="item2.cnt" :class="[item2.partnerLevel, {'moreOn':item2.open}]" @click="listOpen(item2.memId, 'sub', item2)"></span>
                  <span class="arr normal" v-if="!item2.cnt"></span>
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">{{ $t('front.common.cp') }}</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId" :class="{'morebar':item3.open}">
                      <span class="arr" v-if="item3.cnt" :class="[item3.partnerLevel, {'moreOn':item3.open}]" @click="listOpen(item3.memId, 'sub', item3)"></span>
                      <span class="arr normal" v-if="!item3.cnt"></span>
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{ $t(partnerLevelName(item3.partnerLevel)) }}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId" :class="{'morebar':item4.open}">
                          <span class="arr" v-if="item4.cnt" :class="[item4.partnerLevel, {'moreOn':item4.open}]" @click="listOpen(item4.memId, 'sub', item4)"></span>
                          <span class="arr normal" v-if="!item4.cnt"></span>
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{ $t(partnerLevelName(item4.partnerLevel)) }}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
              </colgroup>
              <thead>
                <!-- <tr>
                  <th>IDX</th>
                  <th>받은아이디</th>
                  <th>닉네임</th>
                  <th>내용</th>
                  <th>전송금액</th>
                  <th>처리 후 캐시</th>
                  <th>내 보유 캐시</th>
                  <th>전송일시</th>
                  <th>처리 여부</th>
                  <th>비고</th>
                </tr> -->
                <tr>
                  <th>{{ $t('front.board.number') }}</th>
                  <th>{{ $t('front.common.targetID') }}</th>
                  <th>{{ $t('front.common.nickName') }}</th>
                  <th>{{ $t('front.search.content') }}</th>
                  <th>{{ $t('front.common.beforeProcess') }}</th>
                  <th>{{ $t('front.common.processingAmount') }}</th>
                  <th>{{ $t('front.common.afterProcess') }}</th>
                  <th>{{ $t('front.common.processTime') }}</th>
                  <th>{{ $t('front.give.yesorno') }}</th>
                  <th>{{ $t('front.give.etc') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="list.length > 0">
                  <template v-for="(item, index) in list" v-bind:key="item.cashIdx">
                    <tr>
                      <td>{{((pageInfo.page-1) * 10) +  (index + 1)}}</td>
                      <td>{{item.botMemId}}</td>
                      <td class="nick">{{item.botMemNick}}</td>
                      <td class="blc" v-if="item.cashType === '-3'">{{$t('front.give.give')}}</td>
                      <td class="rdc" v-else>회수</td>
                      <td>{{thousand(item.preCashAmt)}}</td>
                      <td :class="{'rdc': item.cashType ==='3', 'blc':item.cashType ==='-3'}">{{thousand(item.cashAmt)}}</td>
                      <td>{{thousand(item.aftCashAmt)}}</td>
                      <td>{{dateFormatAll(item.regDate)}}</td>
                      <td class="blc" v-if="item.cashStatus === '1'">O</td>
                      <td class="rdc" v-else>X</td>
                      <td>{{item.memo}}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="list.length > 0">
                    <template v-for="(item, index) in list" v-bind:key="item.cashIdx">
                      <ul>
                        <li><em>번호</em><span class="pl5">{{((pageInfo.page-1) * 10) +  (index + 1)}}</span></li>
                        <li><em>처리 전</em><span class="pl5">{{thousand(item.preCashAmt)}}</span></li>
                        <li><em>대상 아이디</em><span class="pl5">{{item.botMemId}}</span></li>
                        <li><em>처리금액</em><span class="pl5" :class="{'rdc': item.cashType ==='3', 'blc':item.cashType ==='-3'}">{{thousand(item.cashAmt)}}</span></li>
                        <li><em>닉네임</em><span class="pl5 nick">{{item.botMemNick}}</span></li>
                        <li><em>처리 후</em><span class="pl5">{{thousand(item.aftCashAmt)}}</span></li>
                        <li v-if="item.cashType === '-3'"><em>내용</em><span class="pl5 blc">{{$t('front.give.give')}}</span></li>
                        <li v-else><em>내용</em><span class="pl5 rdc">회수</span></li>
                        <li><em>처리 일시</em><span class="pl5">{{dateFormatAll(item.regDate)}}</span></li>
                        <li v-if="item.cashStatus === '1'"><em>처리 여부</em><span class="pl5 blc">O</span></li>
                        <li v-else><em>처리 여부</em><span class="pl5 rdc">X</span></li>
                        <li><em>비고</em><span class="pl5">{{item.memo}}</span></li>
                      </ul>
                    </template>
                  </template>
                  <template v-else>
                  <ul>
                      <li class="nodata">내역 없음</li>
                  </ul>
                </template>
              </div>
          </div>
        </div>
      </div>
      <pagination v-if="pageInfo && currentTab === 'moneytab_2'"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="loadData"
      />
    </div>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination.vue'
import { getCashSendList, memCashInOut } from '@/api/give'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { getRetailMyLoginMem, partnerLevels, partnerMyMemIds, retailMyPartnerDirectList } from '@/api/retail'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
import { mapState } from 'vuex'

export default {
  name: 'PartnerMoneyMove',
  components: { Pagination, DateFilter, DateFilterMobile },
  data () {
    return {
      currentTab: 'moneytab_2',
      model: {},
      selectMemId: '',
      selectMem: {
        cashAmt: ''
      },
      cashAmt: '',
      list: [],
      searchForm: {
        topMemId: '',
        cashType: '',
        cashStatus: '',
        botMemId: '',
        startDate: getDateStr(getSubDaysDate(new Date(), 7)),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd')
      },
      partnerLevelList: [],
      partnerList: [],
      selectPartnerCode: '',
      myMemIdsList: [],
      isProcessing: false,
      loginMemList: [],
      selectedLi: null,
      idlist: false
    }
  },
  watch: {
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      } else {
        this.selectMem = {}
        this.selectMem.cashAmt = ''
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ]),
    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  async created () {
    this.getPartnerLevels()
    await this.getLoginMember()
    this.getMyPartnerList(this.userData.memId)
    this.loadData()
  },
  methods: {
    thousand,
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    onChangeTab (name, param) {
      console.log('onChangeTab : ', name, param)
      if (param && param.code) {
        console.log('onChangeTab param : ', param)
        this.currentTab = param.code
      } else {
        this.currentTab = name
      }
      this.goPageByName(name, param)
    },
    async getLoginMember () {
      const res = await getRetailMyLoginMem()
      console.log(res)
      const result = res.data
      if (result.resultCode === '0') {
        this.loginMemList = result.data.loginMemList
      }
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    listOpen (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId
      retailMyPartnerDirectList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = !item.open
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId

      console.log('[REQ]retailMyPartnerDirectList : ', params)
      retailMyPartnerDirectList(params).then(res => {
        console.log('[RES]retailMyPartnerDirectList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.loadData(1)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
        }
      })
    },
    onChangeStartDate (event) {
      const startDate = event.target.value
      this.searchForm.startDate = startDate
    },
    onChangeEndDate (event) {
      const endDate = event.target.value
      this.searchForm.endDate = endDate
    },
    loadData (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      if (this.selectedLi) {
        this.searchForm.topMemId = this.selectedLi
      }
      const form = JSON.parse(JSON.stringify(this.searchForm))
      form.startDate += ' 00:00:00'
      form.endDate += ' 23:59:59'
      // form.page = page

      const params = {
        ...form,
        page: page,
        count_per_list: 40
      }
      this.emitter.emit('Loading', true)
      console.log('getCashSendList : ', params)
      getCashSendList(params).then(response => {
        this.emitter.emit('Loading', false)
        console.log('getCashSendList : ', response)
        const result = response.data
        if (result.resultCode === '0') {
          this.list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    },
    async memCash (type) {
      if (!this.cashAmt) {
        await this.onAlert('warningart', 'front.give.moneyInput')
        return false
      }

      if (!this.selectMem.memId) {
        await this.onAlert('warningart', '회원 아이디를 선택해주세요.')
        return false
      }

      if (!this.isProcessing) {
        this.isProcessing = true

        const params = {
          memId: this.selectMem.memId
        }
        if (type === '-3') {
          params.inAmt = this.cashAmt
          if (Number(this.cashAmt) > Number(this.userData.cashAmt)) {
            await this.onAlert('warningart', '보유금보다 지급 금액이 큽니다.')
            this.isProcessing = false
            return false
          }
        } else {
          params.outAmt = this.cashAmt
          if (Number(this.cashAmt) > Number(this.selectMem.cashAmt)) {
            await this.onAlert('warningart', '보유금보다 회수 금액이 큽니다.')
            this.isProcessing = false
            return false
          }
        }

        const message = this.$t('front.cash.moneyMoveConfirm', { memNick: this.selectMem.memNick, cashAmt: thousand(this.cashAmt), type: type === '-3' ? '지급' : '회수' })

        const confirm = await this.onConfirm(message)
        if (confirm) {
          this.emitter.emit('Loading', true)
          memCashInOut(params, type).then(async response => {
            const result = response.data
            this.emitter.emit('Loading', false)
            if (result.resultCode === '0') {
              if (type === '-3') {
                this.onCheck('지급 완료')
              } else {
                this.onCheck('회수 완료')
              }

              this.selectPartnerCode = ''
              this.cashAmt = ''
              this.loadData()
            } else {
              await this.onAlert('warningart', result.resultMessage)
            }
            this.isProcessing = false
          })
        } else {
          this.isProcessing = false
        }
      }
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
.datesearchPT input[type="date"]::-webkit-calendar-picker-indicator{/*background: url(../../../assets/img/PTcalender.svg);display: block;background-repeat: no-repeat;background-size: contain;*/}
.strTablescr {background: none; box-shadow: none;}

@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
}
.bgb {background: #0019ff;}
.bgr {background-color: #f00;}
</style>
